import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
import VueMatomo from 'vue-matomo';
import VueGtm from '@gtm-support/vue2-gtm';
import axios from 'axios';
import { MsalPlugin, MsalPluginOptions } from './utilities/msalPlugin/msalPlugin';

Vue.config.productionTip = false;
Vue.use(Antd);
const loginVar = process.env.VUE_APP_REQUIRES_LOGIN;

const msalOptions: MsalPluginOptions = {
  requiresLogin: loginVar === 'true',
  clientId: '' + process.env.VUE_APP_AZURE_AD_CLIENT_ID,
  loginAuthority:  '' + process.env.VUE_APP_AZURE_AD_LOGIN_AUTHORITY,
  knownAuthority: '' + process.env.VUE_APP_AZURE_AD_KNOWN_AUTHORITY,
  redirectUri: '' + process.env.VUE_APP_AZURE_AD_REDIRECT_URI,
  scope: '' + process.env.VUE_APP_AZURE_AD_SCOPE,
};

Vue.use(new MsalPlugin(), msalOptions);

Vue.use({
  install(vue, opts = {}) {
    vue.prototype.$axiosClient = axios.create({});
  },
});

Vue.use(VueMatomo, {
  // Tracking site
  host: process.env.VUE_APP_MATOMO_URL,
  siteId: process.env.VUE_APP_MATOMO_SITE_ID,

  // Router pageviews
  router,

  // External links
  enableLinkTracking: true,
  requireConsent: false,
  trackInitialView: true,

  // Track page time accurately
  enableHeartBeatTimer: true,
  heartBeatTimerInterval: 15,

  // This is a correlation ID we can set uniquely, separate to the custom dimensions
  userId: undefined,

  // A list of pre-initialization actions that run before matomo is loaded
  // Default: []
  // Example: [
  //   ['API_method_name', parameter_list],
  //   ['setCustomVariable','1','VisitorType','Member'],
  //   ['appendToTrackingUrl', 'new_visit=1'],
  //   etc.
  // ]
  preInitActions: [],
});

Vue.use(VueGtm, {
  id: process.env.VUE_APP_GTM_ID,
  defer: false,
  compatibility: false,
  trackOnNextTick: false, // Whether or not call trackView in Vue.nextTick
  enabled: true,
});

window._paq.push(['getVisitorId']);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
